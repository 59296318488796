.k-grid .k-state-selected {
  background: var(--row-selection) !important;
  color: black !important;
}

.red {
  background-color: red;
  color: white;
}
.green {
  background-color: green;
  color: white;
}

th.k-sorted {
  background-color: var(--dark-header);
}
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: var(--row-selection) !important;
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected td {
  background-color: var(--row-selection) !important;
}

.test {
  position: fixed;
  max-width: 98%;
}
/* .k-grid-header col:nth-of-type(1){
    width: 30%
}
.k-grid-table col:nth-of-type(1){
    width: 30%
} */
.headerClass1 {
  background-color: var(--dark-header) !important;
  color: white !important;
}

.k-grid-header .k-header > .k-link {
  white-space: normal;
  padding: 0 0 0 0;
  text-align: center;
}

.k-grid tr {
  cursor: pointer !important;
}
.subHead {
  font-size: var(--font-size-button);
  color: var(--text-disabled);
  padding-left: var(--spacing-xs);
}

.tab {
  min-height: 480px;
  margin-top: var(--spacing-md);
  margin-left: var(--spacing-sm);
  margin-right: var(--spacing-sm);
  cursor: pointer !important;
}

.k-column-title {
  font-weight: var(--weight-medium) !important;
  white-space: normal !important;
}

.btn:hover {
  cursor: pointer;
}

.flex-container {
  display: flex;
}
/* @media (max-width: 800px) {
    .flex-container {
      flex-direction: row;
    }
  } */

.commodityCodeView {
  margin-bottom: 2px;
}
.checkbox-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-rows: auto auto; */
  grid-column-gap: 50px;
  justify-items: start;
}
.headerAdd {
  display: grid;
  border: solid 1px;
  grid-template-columns: 1fr 1fr 1fr;
  background: #767070;
  color: white;
  grid-column-gap: 60px;
  justify-items: center;
  padding-left: 50px;
  width: auto;
}
.dialogHeader {
  display: grid;
  border: solid 1px;
  grid-template-columns: 0.5fr 1.2fr;
  background: #767070;
  color: white;
  grid-column-gap: 1px;
  justify-items: center;
  width: auto;
}
.dialogheaderContent {
  text-align: center;
  /* border-right: 1px solid rgba(0, 0, 0, 0.08);
  padding-top: 3px;
  width: -webkit-fill-available; */
}
.dialogContent {
  /* margin-bottom: 8;
   margin-top: 8;  */
  padding: 5px 3px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.allocHeader {
  display: grid;
  border: solid 1px;
  grid-template-columns: 0.5fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  background: #767070;
  color: white;
  grid-column-gap: 1px;
  justify-items: center;
  /* width: auto; */
}

.scrAdd {
  height: 100px;
  min-height: 200px;
  overflow-y: auto;
}
.dialogBody {
  display: grid;
  grid-template-columns: 0.5fr 1.2fr;
  text-align: center;
  height: 200px;
  min-height: 300px;
  overflow-y: auto;
}
.allocBody {
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  text-align: center;
  height: 200px;
  min-height: 300px;
  overflow-y: auto;
}
/* .checkbox-first{
  grid-column: 1;
  width: max-content;
  margin-left: 20px;
} */
/* .checkbox-label{
  grid-column: 2;
width: max-content;
margin-top: 8px;
padding-left: 20px;
} */
/* .checkbox-date{
  grid-column: 3;
  width: max-content;
  margin-top: 8px;
  padding-left: 20px;
} */
.input-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-column-gap: 8px;
  /* margin-top: 10px; */
}
.input-first {
  grid-column: 1;
  width: max-content;
}
.input-second {
  grid-column: 2;
  width: max-content;
}
.container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  width: 650px;
}
.input-header-cell {
  font-weight: var(--text--bold);
}
.input-first-cell {
  padding-top: 10px !important;
  grid-column: 1;
  width: 180px;
}
.test1 {
  position: absolute;
}
.cell {
  background-color: lightgrey;
}
.input-second-cell {
  grid-column: 2;
  padding-top: 10px;
  width: 180px;
}

.container-box {
  margin-top: 20px;
  border: 1px solid #ebebeb;
  /* width: 100%;
   height: 70%; */
}
.head {
  font-size: var(--font-size-title);
  font-weight: var(--weight-medium);
  padding-left: var(--spacing-xs);
}
.input-cell {
  font-size: "16px";
  font-size: "max(16px, 1em)";
  font-family: inherit;
  padding: 0.25em 0.5em;
  background-color: " #fff";
  border: "2px solid #f0f0f0";
  border-radius: "4px";
  width: "100px";
  color: "#424242";
}
.cost_dialog_row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
}
.save-button {
  background-color: var(--brand-primary) !important;
  color: #fff;
}

.resDoller::after {
  font-weight: bold;
  color: #ffff;
  content: " ($) ";
  margin-left: 49%;
}

.action-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.k-checkbox:checked,
.k-checkbox.k-checked {
  color: white;
  background-color: #03a9f4;
  border-color: #03a9f4;
}
/* .k-checkbox:checked:focus, .k-checkbox.k-checked.k-focus {
  box-shadow: 0 0 0 2px rgb(0,0,0 / 6%) !important
}
.k-checkbox:focus, .k-checkbox.k-checkbox:focus{
  box-shadow: 0 0 0 2px rgb(0,0,0 / 6%) !important
} */
.numberFields {
  text-align: right !important;
}
.k-grid-header {
  text-align: center;
}
.searchWindow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 165px;
  width: 200px;
  margin-top: 10px;
}
.excludeTableHead {
  display: grid;
  border: solid 1px;
  grid-template-columns: 55px 170px 180px 210px 180px 140px;
  background: #767070;
  color: white;
  grid-column-gap: 1px;
  justify-items: center;
  width: auto;
}
.crTableHead {
  display: grid;
  border: solid 1px;
  grid-template-columns: 80px 180px 180px;
  background: #767070;
  color: white;
  grid-column-gap: 1px;
  justify-items: center;
  width: auto;
}
.excludeTableBody {
  height: 200px;
  min-height: 300px;
  overflow-y: auto;
}
.viewTable {
  height: 200px;
  min-height: 300px;
  overflow-y: auto;
}
.action-button {
  background-color: #228dff !important;
  color: #fff;
  margin: 0.5rem;
}
.dltSearchWindow {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 10px;
  height: 90px;
}
.deleteTableHead {
  display: grid;
  border: solid 1px;
  grid-template-columns: 55px 170px 170px 210px 180px;
  background: #767070;
  color: white;
  grid-column-gap: 1px;
  justify-items: center;
  width: auto;
}