.qtr_header{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-bottom: 1px solid lightgray;
  padding: 0.5rem;
}

.qtr_header > span {
  font-size: var(--font-size-title);
  font-weight: var(--weight-medium);
  padding-left: var(--spacing-xs);
}

.spacer {
  flex: 1 1 auto
}
.numberFields {
  text-align: right !important;
}
