.wizard-page-hedding{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 0;
    padding: 0.5rem 1rem;
    font-size: 13px;
    font-weight: 500;
    height: 18px;
}

.wizard-page-hedding > p {
    margin: 0;
}
.next-steps{
    margin-left: 0.2rem !important;
    color: black;
    font-weight: 500;
    font-size: 14px;
}
.wizard-page-hedding .over-flow {
    width: 250px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 0;
    text-align: left;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
  } 