.home-container{
    height: auto;
    padding: 1rem;
}

.home-filter-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-bottom: 1rem;
    align-items: center;
}

.home-search-input{
    width: 250px !important;
    background-color: #fff;
    border: 1px solid lightgray !important;
    background: url("/src/assets/search.png") no-repeat left;
    background-size: 20px;
    background-position: right;
}

.home-card-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%; /* 100vw */
}

.home-card{
    min-height: 80px;
    max-height: 80px;
    width: 250px;
    margin: 0 1rem 1rem 0;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    cursor: pointer;
    background: rgb(255,255,255);
background: linear-gradient(124deg, rgba(255,255,255,1) 0%, rgba(255,244,248,0.7852183109571954) 100%);
}

.home-card:hover{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}


.card-title{
    font-size: 15px !important;
    max-width: 120px;
}

.card-header{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}