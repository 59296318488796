.k-radio:checked,
.k-radio.k-checked {
  border-color: dodgerblue;
  color: white;
  background-color: dodgerblue;
}
.k-radio:checked:focus,
.k-radio.k-checked.k-state-focus,
.k-radio.k-checked.k-focus {
  box-shadow: 0 0 0 2px dodgerblue;
}
/* .contentWidth {
    padding: 25px;
} */
.k-radio {
  border-color:#808080 !important;
}
.k-checkbox {
  border-color: #808080 !important;
}