 .headerClass{
    background-color: #545454;
    color: white;
    justify-content: center;
    padding-left: 10px !important;
}
.greenHeader{
  background-color: green;
    color: white;
    justify-content: center;
    padding-left: 10px !important;
}
.upload{
  color: #4492e5;
  width: 150px;
    font-weight: 500;
    background: white;
    border-color: #4492e5;
}
.upload:hover{
  color: #4492e5;
  width: 150px;
    font-weight: 500;
    background: white;
    border-color: #4492e5;
}
.currQuarter{
  float: right;
  margin-top: 3px; 
  font-size: 14px;
}
.k-grid .k-grid-filter, .k-grid .k-header-column-menu, .k-grid .k-hierarchy-cell .k-icon{
  color:white;
}
.spacer {
  flex: 1 1 auto
}
.k-grid .k-grid-filter:hover, .k-grid .k-header-column-menu, .k-grid .k-hierarchy-cell .k-icon{
  color:white;
  background-color: var(--dark-header);
}
.redHeader{
  background-color: red;
    color: white;
    justify-content: center;
    padding-left: 10px !important;
}
.parentHeaderClass{
    background-color: var(--light-header);
    color: black;
    justify-content: center;
}

.k-grid .k-state-selected {
    background: #f1e5fd !important;
    
  }

  
  th.k-sorted {
    background-color: var(--dark-header)
  }
  .k-grid td.k-state-selected, .k-grid td.k-selected, .k-grid tr.k-state-selected > td, .k-grid tr.k-selected > td {
    background-color: #d9e9fb !important;
    
  }
  
  
  .k-grid-header .k-header>.k-link {
    white-space: normal;
    padding: 0 0 0 0;
    text-align: center;

  }
  
  .k-grid tr {
    cursor: pointer !important;
  } 

  .k-pager-numbers .k-link.k-state-selected, .k-pager-numbers .k-link.k-selected{
    color: #53A5FF !important;
    background-color: #d9e9fb;
  }

  .k-pager-numbers .k-link{
    color: #53A5FF !important
  }


/* App Specific Styles */

/* Data Grid */

.k-grid-header .k-header {
  vertical-align: middle !important;
}

.k-grid{
  min-height: 80vh;
  max-height: 80vh;
}

/* Button */

.app-button {
    background: none;
    border: none;
    margin:0 0.1rem 0 0;
    border-radius: 0;
}

.app-button:focus {
  box-shadow: none;
}

.app-button:hover {
  background-color: #f1e5fd;
}

/* Dialog Box */

.window-container{
  width: 100vw;
  height: 100vh;
  position: absolute;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
}

.k-dialog-titlebar{
  background-color: #228dff !important;
}

.save-button{
  background-color:#228dff !important;
  color: #fff;
  margin: 0.5rem;
  float: right;
}

.spacer{
  flex: 1 1 auto
}

/* --------Media Query for nevBar responsive------- */
@media only screen and (max-width: 1920px) {
  .k-drawer-content{
    width: 87% !important;
  }
}
@media only screen and (max-width: 1600px) {
  .k-drawer-content{
   width: 85% !important;
  }
}
@media only screen and (max-width: 1425px) {
  .k-drawer-content{
    width: 83% !important;
   }
}
@media only screen and (max-width: 1280px) {
  .k-drawer-content{
    width: 81% !important;
   }
}
