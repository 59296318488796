.header-container{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 0;
    padding: 0.5rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.logo-image{
    height: 6vh;
    margin-top: -0.9rem;
}

.header-saperator{
    margin: 0 1rem
}

.brand-title{
    font-size: 0.8rem;
}

.user-container{
    display: flex;
    flex-flow: column nowrap;
}

.user-container span:nth-child(2) {
    font-size: 10px;
    color: #676767;
    font-weight: 300;
}