.page-header-container{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 0;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid lightgray;
    font-size: 14px;
    font-weight: 600;
}

.page-header-container > p {
    margin: 0;
}

.days-left-msg{
    margin-left: 0.2rem !important;
    color: #ef5f8f;
    font-weight: 500;
    font-size: 14px;
}
.actulizationHeader{
    display: grid;
    border: solid 1px;
    grid-template-columns: 0.2fr 1fr 1fr 1fr;
    background: #767070;
    color: white;
    justify-items: center;
    width: auto;
}
.actBody{
    height: 100px;
    min-height: 200px;
    overflow-y: auto;
} 
.actContent {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 0.2fr 1fr 1fr 1fr;
    text-align: center;
    margin-left: -49px;
}
.actbutton{
    background-color:#228dff !important;
    color: #fff;
}