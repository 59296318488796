.step {
  font-size: x-small;
}
.k-stepper .k-step.k-step-disabled .k-step-indicator,
.k-stepper .k-step:disabled .k-step-indicator {
  color: #8f8f8f;
}
.k-stepper .k-step-done.k-step-disabled .k-step-indicator,
.k-stepper .k-step-done:disabled .k-step-indicator {
  background-color: #56b056;
  color: white;
  border-color: #56b056;
  width: 20px;
  height: 20px;
}
.k-stepper .k-step-current.k-step-disabled .k-step-indicator,
.k-stepper .k-step-current:disabled .k-step-indicator {
  background-color: green;
  color: white;
  border-color: green;
  width: 20px;
  height: 20px;
}
span.k-step-indicator-icon.k-icon.k-i-close {
  background-color: red;
  color: white;
  border-radius: 21px;
  border-color: red;
  width: 20px;
  height: 20px;
}
.k-stepper .k-step-indicator {
  width: 20px;
  height: 20px;
}

span.k-step-indicator-icon.k-icon.k-i-warning {
  background-color: rgb(255, 123, 0);
  color: white;
  border-radius: 21px;
  border-color: rgb(255, 123, 0);
  width: 20px;
  height: 20px;
}
.disableFirstStep {
  color: #8f8f8f;
  /* background-color: #8f8f8f; */
  /* border-color:#8f8f8f; */
}
