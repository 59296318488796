.col{
    text-align:center !important;
}

@media only screen and (max-width: 1920px) {
    .k-grid.uploadStatus {
         min-height: 34vh !important;
         height: 690px;
     }

 }