.popUp-header {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  margin-top: 10px;
}
.heading {
  display: grid;
  grid-template-columns: 0.75fr 1.2fr 0.9fr 0.5fr;
  font-weight: bold;
  margin-top: 13px;
  column-gap: 16px;
  margin-left: 28px;
}
.fieldContainer {
  display: grid;
  grid-template-columns: 0.6fr 0.8fr 1fr 0.5fr;
  column-gap: 1em;
  align-items: baseline;
  padding-top: 12px;
}
.numberFields {
  text-align: right !important;
}
.textFields {
  text-align: center !important;
}
.k-column-title {
  text-align: center !important;
}
.window-costAllocation {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.alloc-validation-msg {
  color: #ef5f8f;
  font-weight: 500;
  font-size: 14px;
  margin: 8px 0 0 0;
}
.red {
  background-color: red;
  color: white;
}
