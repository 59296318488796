.marRight{
    margin-left: 30px;
    font-weight: 500;
}
.dateTimepicker1{
    width: 242px !important;
    margin-right: 74px;
}
.gcmClass{
        position: absolute;
        right: 10px;
        top: 9px;
       
}

.dateClass{width: 239px !important;
margin-right: 71px;
}

.dateTimepicker2 {
    width: 242px !important;
    margin-right: 74px;
    opacity: 0.5;
}