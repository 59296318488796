.commoditygroup {
  left: 40%;
  position: absolute;
  font-size: 15px;
  margin-top: 5px;
  display: inline;
}
.home-filter-input {
  width: 150px;
  background-color: #fff;
  border: 1px solid lightgray !important;
}
.internalCol {
  font-size: medium;
}
.info {
  font-size: 15px;
  float: right;
  margin-right: 16px;
}
.popUpFields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  align-items: baseline;
  padding-top: 10px;
  padding-bottom: 4px;
}
.individualFields {
  display: flex;
  justify-content: space-between;
  padding-left: 2px;
  width: 100%;
  align-items: baseline;
  white-space: nowrap;
  padding-bottom: 8px;
}
.comm_code {
  border-color: rgb(67 54 54 / 8%) !important;
  width: 100%;
  height: 31px;
  border-radius: 3px;
  color: #424242;
  background-color: #ffffff;
}
.comm_code:focus {
  border-color: rgba(0, 0, 0, 0.08) !important;
  width: 100%;
  height: 31px;
  border-radius: 3px;
  color: #424242;
  background-color: #ffffff;
}
.loading {
  left: 50%;
  width: 100%;
  position: absolute;
  top: 50%;
}
.numberFields {
  text-align: right !important;
}
.labels {
  font-weight: bold;
}
.discount-container {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  font-weight: bold;
  margin-top: 4px;
}
.discountFieldContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  align-items: baseline;
  padding-top: 12px;
}
