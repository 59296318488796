.react-date-picker__wrapper {
  border: thin solid rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.react-date-picker {
  width: 87% !important;
}
.k-text-start,
.k-buttons-start {
  justify-content: end !important;
}
.k-form-hint {
  font-size: 9px;
  margin: 0 30px 0 0;
}
