.form-element {
  margin: 1rem 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.selectedFileName {
  margin-top: -11px;
  display: flex;
  justify-content: flex-end;
}
