.lov-pop-up .k-window-content{
    padding: 0px 0px
}

@media only screen and (max-width: 1920px) {
    .k-grid.lov-pop-up-grid{
         font-size: 13px;
         min-height: 34vh !important;
         height: 460px;
     }
 
     .lov-pop-up {
         height: 528px !important;
     }
 }
 
  @media only screen and (max-width: 2560px) {
     .k-grid.lov-pop-up-grid{
          font-size: 13px;
          min-height: 37vh !important;
          height: 435px;
      }
 
      .lov-pop-up{
         height: 485px !important;
         width: 410px !important;
     }
  }
 