/* header line style */
.header-margin{
  margin:var(--spacing-xs) var( --font-size-title) 0px var(--font-size-caption);
}
.head {
  font-size: var(--font-size-title);
  font-weight: var(--weight-medium);
  padding-left: var(--spacing-xs);
}


.k-grid .k-state-selected {
  background: var(--text-secondary) !important;
  
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected>td {
  background-color: var(--text-secondary) !important;
 
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected td {
  background-color: var(--text-secondary) !important;
  
}

.k-grid-header .k-header>.k-link {
  white-space: normal;
}

.k-grid tr {

  cursor: pointer !important;
}
.subHead {
  font-size: var(--font-size-button);
  color: var(--text-disabled);
  padding-left: var(--spacing-xs);
}

.k-splitter {
  background: var(--text-background);
  border: 'none' !important;
  border-width: 0px;
  border-style: none;
}

.splitBody {

  border: 'none' !important;
  margin-top: var();
}

.tab {
  min-height: 480px;
  margin-top: var(--spacing-md);
  margin-left: var(--spacing-sm);
  margin-right: var(--spacing-sm);
  cursor: pointer !important;
}

.shouldCostFilter{
  left: -131px !important;
}

.filBtn {
  margin-left:0px;
}
.k-popover{
  margin-left: 194px;
}
.k-column-title {
  font-weight: var(--weight-medium) !important;
  white-space: normal !important;
}

.btn:hover {
  cursor: pointer;
}

.flex-container {
  display: flex;
}
.container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-column-gap: 8px;
  width: 450px;
  padding-left: 25px;
}
.input-first-cell {
  padding-top: 10px;
  width: 100px;
  grid-column: 1;
  font-size:var(--text--bold);
}
.input-second-cell {
  grid-column: 2;
  padding-top: 10px;
  width: 150px;
}
.input-third-cell {
  grid-column: 3;
  padding-top: 10px;
  width: 150px;
}
.input-header-cell{
  font-weight: var(--text--bold);
}




@media (max-width: 800px) {
  .flex-container {
    flex-direction: row;
  }
}

.commodityCodeView {

  margin-bottom: 2px;

}
.numberFields {
  text-align: right !important;
}
