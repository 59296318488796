.primaryBtn{
    color: white;
    background-color: var(--brand-primary)
}
.primaryBtn:hover{
    color: white;
    background-color: var(--brand-primary)
}
.overridecheck{
    margin-left: -3px;
    width: 30px;
    height: 18px;
}
.k-switch-off .k-switch-track {
    border-color: #ebebeb !important;
    color: #424242 !important;
    font-weight: 500 !important;
    background-color: #ffffff !important;
}
.switch{
    margin-right:27px;
}
.k-switch-on .k-switch-track {
    border-color: dodgerblue !important;
    color: white;
    font-weight: 500 !important;
    background-color: dodgerblue !important;
}

.k-switch-md .k-switch-track {
    width: 91px !important;
    height: 30px;
}
.window-shouldCostUpload{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top:0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
}