.quarter_dialog_row{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 10px;
}


.window-container{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top:0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
}

.dateTimepicker{
    width: 160px !important;
}
.react-datetime-picker__wrapper {
    border: thin solid rgba(0,0,0,0.08) !important;
    border-radius: 5px;
}
.validation-msg {
    color: #ef5f8f;
    font-weight: 500;
    font-size: 14px;
  }