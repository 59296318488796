.searchBtn {
  color: white;
  background-color: var(--brand-primary);
}
.searchBtn:hover {
  color: white;
  background-color: var(--brand-primary);
}
.topMargin {
  margin-top: 30px;
}
.commodityCodeViewContainer {
  text-align: center;
}
/* header line style */
.header-margin {
  margin: var(--spacing-xs) var(--font-size-title) 0px var(--font-size-caption);
}
.head {
  font-size: var(--font-size-title);
  font-weight: var(--weight-medium);
  padding-left: var(--spacing-xs);
}
.commodity-views-header {
  padding: 0.1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.innerGrid {
  width: "100%";
  margin-top: "1%";
  min-height: auto !important;
  min-width: auto !important;
}
