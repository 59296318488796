@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root{
  --ct-page-title-color:#fafbfe;
  --brand-primary: #0277BD;
  --brand-secondary: #313a46;
  --brand-backgroud:#fafafa;
  --brand-muted: #C6DAF7;
  --ui-primary: #0277BD;
  --ui-secondary: #3B3B3B;
  --ui-tertiary: #F1F1F1;
  --ui-quaternary: #FFFFFF;
  --ui-disabled: #DEDEDE;
  --ui-error: #D0421B;
  --ui-success: #138000;
  --bg-primary: #FAFAFA;
  --bg-secondary: #F1F1F1;
  --text-primary: #FFFFFE;
  --text-secondary: #7e8c9d;
  --text-background:#F8F3FA;
  --text-disabled: #9C9C9C;
  --text-inverse: #c8c8c8;
  --text-error: #D0421B;
  --text-success: #138000;
  --weight-regular: 400;
  --dark-header:#767070;
  --light-header:lightgrey;
  --row-selection:#96cdee;
  --weight-medium: 500;
  --weight-bold:700;
  --font-size-caption: 12px;
  --font-size-button:14px;
  --font-size-body:16px;
  --font-size-title:20px;
  --spacing-xs:8px;
  --spacing-xxs:6px;
  --spacing-sm:16px;
  --spacing-md: 32px;
  --spacing-lg:64px;
  --text--bold:bold;
}


body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff
}
