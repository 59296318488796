.k-grid .k-state-selected {
  background: var(--text-secondary) !important;
}
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: var(--text-secondary) !important;
}
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected td {
  background-color: var(--text-secondary) !important;
}
.k-grid-header .k-header > .k-link {
  white-space: normal;
}
.k-grid tr {
  cursor: pointer !important;
}
.subHead {
  font-size: var(--font-size-button);
  color: var(--text-disabled);
  padding-left: var(--spacing-xs);
}
.k-splitter {
  background: var(--text-background);
  border: "none" !important;
  border-width: 0px;
  border-style: none;
}
.splitBody {
  border: "none" !important;
  margin-top: var();
}
.tab {
  min-height: 480px;
  margin-top: var(--spacing-md);
  margin-left: var(--spacing-sm);
  margin-right: var(--spacing-sm);
  cursor: pointer !important;
}
.fil {
  margin-left: 12px;
}
.k-column-title {
  font-weight: var(--weight-medium) !important;
  white-space: normal !important;
}
.btn:hover {
  cursor: pointer;
}
.k-popover {
  margin-left: 78px;
}
