.topMar{
    margin-top:20px;
}
.inputCont{
    grid-row-gap: 2px;
}
.container1{
    margin-left:200px !important;
}
.cont13{
    grid-column: 3;
    padding-top: 10px;
    width: 180px;
}
.close{
    background-color: red !important;
    color: #fff;
    margin: 0.5rem;
    float: right;
}

.cont12{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-column-gap: 8px;
}
.cont{
    display: grid !important;
    grid-template-columns: auto !important;
    grid-template-rows: auto auto !important;
}
/* .k-window-content{
    padding-bottom: 0px !important;
} */
.input-first {
    grid-column: 1;
    width: max-content;
    padding-top: 0px !important;
  }
  .input-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-column-gap: 8px;
    padding-top: 0px !important;
  }
  .input-first-cell {
    padding-top: 0px !important;
    grid-column: 1;
    width: 180px;
  }
  .input-second {
    grid-column: 2;
    width: max-content;
  }
  .input-second-cell {
    grid-column: 2;
    width: 180px;
  }