.k-drawer-container {
    position: fixed;
      width: 100%;
      height: 100%;
}

.k-drawer-mini .k-drawer.k-drawer-start, .k-drawer-expanded .k-drawer.k-drawer-start, .k-drawer-left.k-drawer-mini .k-drawer, .k-drawer-left.k-drawer-expanded .k-drawer{
  background: #f4f4f4 !important;
}

.k-drawer-item{
  user-select: none;
  padding: 0.6rem 0.5rem !important;
}

.k-drawer-item.k-state-selected, .k-drawer-item.k-selected {
  background-color: #A34EFF !important;
}

.k-drawer-item.k-state-selected:hover, .k-drawer-item.k-selected:hover {
  background-color: #A34EFF !important;
}

.k-drawer-item:hover{
  background-color: #f1e5fd !important;
}

.k-drawer-item .k-item-text{
  white-space: normal !important;
  font-size: 14px;
}