.k-grid td.k-state-selected,
.k-grid td.k-state-selected,
.k-grid-header .k-header > .k-link {
  white-space: normal;
}
.k-grid tr {
  cursor: pointer !important;
}
.subHead {
  font-size: var(--font-size-button);
  color: var(--text-disabled);
  padding-left: var(--spacing-xs);
}
.k-splitter {
  background: var(--text-background);
  border: "none" !important;
  border-width: 0px;
  border-style: none;
}
.splitBody {
  border: "none" !important;
  margin-top: var();
}
.tab {
  margin-top: var(--spacing-md);
  margin-left: var(--spacing-sm);
  margin-right: var(--spacing-sm);
  cursor: pointer !important;
}
.filBtn {
  margin-left: 15px;
}
.k-column-title {
  font-weight: var(--weight-medium) !important;
  white-space: normal !important;
}
.btn:hover {
  cursor: pointer;
}
.k-popover {
  margin-left: 78px;
}
.negMarTop1 {
  margin-left: 12px;
}
.mar {
  margin-left: var(--spacing-xxs);
}
/* header line style */
.header-margin {
  margin: var(--spacing-xs) var(--font-size-title) 0px var(--font-size-caption);
}
.head {
  font-size: var(--font-size-title);
  font-weight: var(--weight-medium);
  padding-left: var(--spacing-xs);
}
.k-popover * {
  border-color: rgba(0, 0, 0, 0.08);
}
.k-i-filter-clear {
  color: #f00f5a;
}
.cell-Allign {
  text-align: center !important;
}
