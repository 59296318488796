.wizard-page-hedding .over-flow2 {
    width: 450px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 0;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
  } 